<template lang="pug">
.auth-window
  .login-window-container__first-step
    .errors_container(slot="default")
      div(v-for="(errors) in warnings")
        small.text-danger(v-for="(error) in errors") {{ error }}
    form(@submit.prevent='signup')
      .form-group(:class="{active: focusField.login && loginWarning}")
        label.title-auth.required(for='login') Имя пользователя
        input#login.form-control(
          :class="{err_outline: loginWarning}"
          v-model.trim='object.login',
          type='text',
          pattern='[A-Za-z0-9-.]{5,20}',
          title='Логин: от 5 до 20 символов',
          placeholder='Введите имя',
          autocomplete='off',
          min='5',
          max='20',
          required=''
          autofocus="true"
          tabindex="1"
          @focus="setFocusField('login')"
        )
        .cloud-error
          span.cloud-error__title Ошибка
          p.cloud-error__text
            | {{loginWarning}}

      .form-group(:class="{active: focusField.email && emailWarning}")
        label.title-auth.required(for='') Почта
        input.form-control(
          :class="{err_outline: emailWarning}"
          v-model.trim='object.email',
          type='email',
          placeholder='Введите почту',
          autocomplete='off',
          required=''
          tabindex="2"
          @focus="setFocusField('email')"
        )
        .cloud-error
          span.cloud-error__title Ошибка
          p.cloud-error__text
            | {{emailWarning}}

      .form-group
        label.title-auth.required(for='') Пароль
        input.form-control(
          ref="passInput",
          v-model.trim='object.password',
          type='password',
          placeholder='Введите пароль',
          autocomplete='off',
          required=''
          tabindex="3"
          @focus="setFocusField('password')"
        )

      .form-group
        label.title-auth.required(for='') Подтверждение пароля
        input.form-control(
          v-model.trim='object.passwordConfirm',
          type='password',
          placeholder='Введите пароль еще раз',
          autocomplete='off',
          required='',
          tabindex="4"
          @focus="setFocusField('passwordConfirm')"
        )
        small.password-tip.text-success(v-if="similar.same") Пароли совпадают
        small.password-tip.text-danger(v-if="similar.different") Пароли не совпадают

      .form-group
        label.title-auth(for='') Код партнера
        input.form-control(
          v-model.trim='object.partnerId',
          type='text',
          placeholder='Пропустите, если не знаете',
          autocomplete='off',
          tabindex="5"
          @focus="setFocusField('partner_id')"
        )

      button.btn.btn__main.w-100.mt-3(type="submit") Создать аккаунт
      .after-content
        input#check2.check(
          v-model="object.checkTerms",
          type='checkbox',
          name='check'
        )
        label.check-label.registration-check-label(for='check2')
          | Я ознакомлен с&nbsp;
          a(@click="openTermsTab") правилами

</template>

<script>
import { mapGetters } from 'vuex';
import { CHECK_EMAIL_UNIQUE, CHECK_LOGIN_UNIQUE, REGISTER_REQUEST } from '@/store/actions/auth';
import passwordMixin from '@/components/mixins/passport';
import { SOCKET_INIT } from '@/store/actions/events';
import analytics from '@/components/mixins/analytics';

export default {
  name: 'RegistrationForm',
  mixins: [passwordMixin, analytics],
  props: {
    nextRoute: {
      type: [Object, String],
      required: true,
    },
  },
  data() {
    return {
      focusField: {
        login: false,
        email: false,
        partner_id: false,
        password: false,
        passwordConfirm: false
      },
      object: {
        login: '',
        email: '',
        partnerId: '',
        password: '',
        passwordConfirm: '',
        checkTerms: false,
      },
      is_login_unique: true,
      is_email_unique: true,
      steps: [
        { 'title': 'login' },
        { 'title': 'password' },
      ],
      fieldsStepRelation: {
        login: 1,
        email: 1,
        password: 2,
        password_confirmation: 2,
        partner_id: 3
      },
      profModeModal: false,
    };
  },
  computed: {
    ...mapGetters([
      'loading',
      'loadingRequest',
      'passTitle',
      'passContent'
    ]),

    isLoading() {
      return this.loading(REGISTER_REQUEST) === 'loading';
    },

    requestData() {
      let req = this.loadingRequest(REGISTER_REQUEST);
      return (req && req.response ? req.response : {});
    },

    warnings() {
      if (this.loading(REGISTER_REQUEST) === 'error') {
        return this.requestData.data.errors;
      }
      return false;
    },

    loginRequestData() {
      let req = this.loadingRequest(CHECK_LOGIN_UNIQUE);
      return (req && req.response ? req.response : {});
    },

    emailRequestData() {
      let req = this.loadingRequest(CHECK_EMAIL_UNIQUE);
      return (req && req.response ? req.response : {});
    },

    loginWarning() {
      if (this.loading(CHECK_LOGIN_UNIQUE) === 'error') {
        if (this.loginRequestData.data) {
          const err = JSON.parse(JSON.stringify(this.loginRequestData.data.errors.login));

          return err.pop();
        }
      }
      return '';
    },

    emailWarning() {
      if (this.loading(CHECK_EMAIL_UNIQUE) === 'error') {
        if (this.emailRequestData.data) {
          const err = JSON.parse(JSON.stringify(this.emailRequestData.data.errors.email));

          return err.pop();
        }

      }
      return '';
    },

    passPopup() {
      return { title: this.passTitle[this.passType.text], content: this.passContent[this.passType.text] };
    }
  },
  watch: {
    'object.password': function(newValue) {
      this.password = newValue;
      this.checkPassword();
    },
    'object.passwordConfirm': function(newValue) {
      this.passwordConfirm = newValue;
      this.checkPassword();
    },
    'object.login': function(newValue) {
      this.debouncedLoginCheck(newValue);
    },
    'object.email': function(newValue) {
      this.debouncedEmailCheck(newValue);
    }
  },
  created() {
    this.debouncedLoginCheck = _.debounce(this.loginCheck, 1500);
    this.debouncedEmailCheck = _.debounce(this.emailCheck, 1500);
  },
  mounted() {
    if(this.$route.params.partner_id){
      this.object.partnerId = this.$route.params.partner_id;
    }
  },
  methods: {
    async loginCheck(val) {
      if (!val) {
        return this.is_login_unique = true;
      }

      try {
        this.is_login_unique = await this.$store.dispatch(CHECK_LOGIN_UNIQUE, val);
      } catch (e) {
        this.is_login_unique = false;
      }
    },

    async emailCheck(val) {
      if (!val) {
        return this.is_email_unique = true;
      }

      try {
        this.is_email_unique = await this.$store.dispatch(CHECK_EMAIL_UNIQUE, val);
      } catch (e) {
        this.is_email_unique = false;
      }
    },

    setFocusField(field) {
      this.resetFocusField();
      this.focusField[field] = true;
    },
    resetFocusField() {
      Object.keys(this.focusField).forEach(key => this.focusField[key] = false);
    },
    signup() {
      if (!this.object.checkTerms) {
        return this.$message.error('Примите правила пользования сервисом.');
      }
      if (this.loginWarning || this.emailWarning) {
        return this.$message.error('Пожалуйста внесите необходимые изменения, чтобы продолжить.');
      }
      if (!this.passType.confirm) {
        return this.$message.error('Пароль должен соответствовать минимальным требованиям.');
      }
      if (!this.similar.same) {
        return this.$message.error('Пароли должны совпадать чтобы продолжить.');
      }

      this.$store.dispatch(REGISTER_REQUEST, this.object).then(() => {
        this.$store.dispatch(SOCKET_INIT);
        this.$router.push(this.nextRoute);
      });
    },
    openTermsTab() {
      const route = this.$router.resolve({ name: 'Terms' });
      window.open(route.href, '_blank');
    },
  }
};
</script>

<style lang="scss" scoped>
.err_outline {
  /*outline: #FF2D55 inset 1px;*/
  box-shadow: 0 0 2pt 1pt #FF2D55 !important;
}

.password-tip {
  font-size: 14px;
}

.modal-type-acc {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
}

.modal-type-acc .modal-body {
  padding: 14px;
}

.modal-content.modal-type-acc__title {
  background-color: initial;
  border: none;
  color: #ffffff;
  text-align: center;
}

.modal-content.modal-type-acc__title h2 {
  font-size: 20px;
  font-weight: normal;
}

.modal-type-acc__block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-height: 360px;
}

.modal-type-acc__block-text {
  cursor: pointer;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.modal-type-acc__block-text span {
  color: #009A3C;
  font-size: 18px;
  font-weight: 500;
}

.modal-type-acc__block-text p {
  font-size: 14px;
  color: #000;
  margin-bottom: 0;
}

.modal-type-acc__block-img {
  min-width: 46px;
  margin-right: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal-type-acc__block-icon {
  background: url("/img/modal-icon.png") no-repeat center;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
  width: 46px;
  height: 46px;
}

.form-modal_title {
  color: #000;
}

.modal-content_active {
  border: 1px solid rgb(86, 167, 65);
}

.text-danger {
  font-size: 14px;
}
.modal-type-acc-info {
  cursor: pointer;
  margin-top: -15px;
  margin-bottom: 15px;
}

.modal-type-acc-info .info-title {
  margin-left: 5px;
}
.modal-type-info-icon {
  float: right;
  color: #2464EB;
}

.registration-box__points {
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
}

.check-label {
  font-size: 14px;
  letter-spacing: 0.4375px;
  color: #9e9e9e;
}
.check-label a {
  color: #9e9e9e;
}

.registration-check-label {
  color: $gray800;

  a {
    color: $blue600;

    &:hover {
      color: $blue600;
      text-decoration: underline;
    }
  }
}
</style>
