<template lang="pug">
  .auth-window
    form(@submit.prevent="signin")
      .auth_information(v-if='isTwoFactorAuth')
        el-alert(
          title="Требуется код подтверждения",
          type="warning",
          :closable="false"
          show-icon
        )
      .auth_information.auth_form_column(v-if='!isTwoFactorAuth && warnings')
        el-alert(
          title="Ошибка авторизации",
          :description="warnings"
          type="error",
          show-icon
        )
      .form-group
        label.title-auth(for='login') Почта
        input#login.form-control(
          type='text',
          name="login",
          key="login",
          v-validate="'required|email'",
          placeholder='Введите почту',
          autocomplete='off',
          v-model="login"
        )
      .form-group
        label.title-auth(for='password') Пароль
        input#password.form-control(
          v-validate="'required'",
          name="password",
          key="password",
          type='password',
          placeholder='Введите пароль',
          ref="password",
          autocomplete='off',
          v-model.trim="password",
        )
      .form-group(v-if='isTwoFactorAuth')
        label.title-auth(for='factor')
          | Код 2FA
        input#factor.form-control(
          v-model.trim='google2fa_secret',
          name='factor',
          placeholder='Ваш код',
          pattern='[0-9]{6}',
          maxlength='6',
          autocomplete='off',
          required=''
        )
      .login-remember__container
        input#check.check(type='checkbox', v-model="remember_me")
        label.check-label(for='check') Запомнить меня
      button.btn.btn-success.w-100.btn-finex.mt-3(
        v-if="!isTwoFactorAuth"
        @click="signin"
        type='submit',
        data-toggle='modal',
        data-target='#exampleModalCenter'
      ) Войти
      button.btn.btn-success.w-100.mt-3(v-if='isTwoFactorAuth' type='submit')
        | Войти
    .after-content
      router-link.login-window__link(@click="sendAnalytics('Signin', 'signin_forgetpass', false)" :to="{'name': 'ForgotPassword'}") Восстановление пароля

</template>

<script>
import { mapGetters } from 'vuex';
import { AUTH_REQUEST } from '@/store/actions/auth';
import analytics from '@/components/mixins/analytics';
import {PROFILE_SET_PARTNER_ID} from "@/store/actions/user";

export default {
  name: 'Login',
  mixins: [analytics],
  props: {
    nextRoute: {
      type: [Object, String],
      required: true,
    },
  },
  data () {
    return {
      login: '',
      password: '',
      remember_me: false,
      google2fa_secret: '',
    };
  },
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'loading',
      'loadingRequest',
    ]),

    isTwoFactorAuth () {
      if (this.loading(AUTH_REQUEST) === 'error') { // TODO: close
        return (
          this.requestData.status === 422 &&
          this.requestData.data.message === 'Требуется код двухфакторной авторизации'
        ); //TODO: другое решение
      }
      return false;
    },

    requestData () {
      let req = this.loadingRequest(AUTH_REQUEST);
      return (req && req.response ? req.response : {});
    },
    warnings() {
      if (this.loading(AUTH_REQUEST) === 'error') {
        return this.requestData.data && this.requestData.data.message;
      }
      return false;
    },

    isLoading() {
      return this.loading(AUTH_REQUEST) === 'loading';
    },
  },
  mounted() {
    this.sendAnalytics('Signin', 'signin', false);
    if(this.$route.params.partner_id) {
      this.$store.commit(PROFILE_SET_PARTNER_ID, this.$route.params.partner_id)
    }
  },
  methods: {
    signin() {
      // todo BUG in auth, if you not check "this.loading(AUTH_REQUEST) === 'loading'" it sends request twice
      if (this.isLoading) {
        return;
      }

      let data = {
        login: this.login,
        password: this.password,
        remember_me: this.remember_me,
      };
      if (this.isTwoFactorAuth) {
        data.google2fa_secret = this.google2fa_secret;
      }
      this.$store.dispatch(AUTH_REQUEST, data).then(() => {
        this.sendAnalytics('Signin', 'signin_submit', false);
        this.sendAnalytics('Signin', 'signin_success', false ['yandex']);
        this.sendAnalytics('Signin', {action: 'identify', argument: this.login}, false, ['mixpanel']);
        this.$router.push(this.nextRoute);
      }).catch(e => {
        console.log('Error auth: ', e);
      });
    },
  }
};
</script>
<style scoped>
  .login-remember__container {
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
  }
</style>
