export default {
  data() {
    return {
      password: '',
      passwordConfirm: '',
      similar: {
        none: true,
        same: false,
        different: false,
      },
      passType: {
        text: 'simple',
        confirm: false,
      },
    }
  },
  methods: {
    checkPassword() {
      this.similar.none = (this.password === '' && this.passwordConfirm === '');
      this.similar.same = (this.password === this.passwordConfirm && this.password !== '' && this.passwordConfirm !== '');
      this.similar.different = (this.password !== this.passwordConfirm);

      this.similar.number = new RegExp(/.*[0-9]/).test(this.password);
      this.similar.lowercase = new RegExp(/.*[a-z]/).test(this.password);
      this.similar.uppercase = new RegExp(/.*[A-Z]/).test(this.password);
      this.similar.special =  new RegExp(/.*[!@#$%^&]/).test(this.password);
      this.similar.letter = new RegExp(/.*[A-Za-z]/).test(this.password);

      if(this.passType.text === 'simple'){
        this.similar.count = this.password.length >= 7;
        if(this.similar.number && this.similar.letter &&  this.similar.count){
          this.passType.text = 'medium';
          this.passType.confirm = true;
        }
      }

      if(this.passType.text === 'medium'){
        this.similar.count = this.password.length >= 9;
        if(this.similar.lowercase && this.similar.count && this.similar.uppercase  && this.similar.count){
          this.passType.text = 'hard';
        }
      }

      if(this.passType.text === 'hard'){
        this.similar.count = this.password.length >= 13;
        if(this.similar.lowercase && this.similar.uppercase && this.similar.count && this.similar.special && this.similar.number){
          this.passType.text = 'super_hard';
        }
      }

      if(this.passType.text !== 'simple' && (!this.similar.number || !this.similar.letter || this.password.length < 7)){
        this.passType.text = 'simple';
        this.passType.confirm = false;
      }else if(this.passType.text !== 'simple' && (!this.similar.lowercase || !this.similar.uppercase || this.password.length < 9)){
        this.passType.text = 'medium';
      }else if(this.passType.text === 'super_hard' && (!this.similar.special || this.password.length < 13)){
        this.passType.text = 'hard';
        this.similar.count = this.password.length >= 13;
      }
    },
  },
};
