<template lang="pug">
  .main-content.layout-main__centering.container.pb-5.mt-5
    .auth__container
      .tabs-auth__container
        .tab-auth.left(:class="{active: tab === 'login'}" @click="changeTab('login')") Вход
        .tab-auth.right(:class="{active: tab === 'register'}" @click="changeTab('register')") Регистрация
      transition(name="auth-component" mode="out-in")
        component(:is="components[tab]" :nextRoute="nextRoute")
</template>

<script>
  import LoginForm from '@/components/Auth/LoginForm';
  import RegisterForm from '@/components/Auth/RegistrationForm';
  import {PROFILE_SET_PARTNER_ID} from "../store/actions/user";

  export default {
    name: 'AuthForm',
    components: {
      LoginForm,
      RegisterForm
    },
    props: {
      nextRoute: {
        type: [Object, String],
        default: '/',
      },
    },
    data () {
      return {
        tab: 'login',
        components: {
          login: LoginForm,
          register: RegisterForm,
        }
      };
    },
    watch: {
      '$route.meta.tab'(val) {
        this.tab = val;
      }
    },
    mounted() {
      if(this.$route.meta.tab) {
        this.tab = this.$route.meta.tab;
      }
      if(this.$route.params.partner_id) {
        this.$store.commit(PROFILE_SET_PARTNER_ID, this.$route.params.partner_id)
      }
    },
    methods: {
      changeTab(tab) {
        if (tab === 'register') {
          this.$router.push({ name: 'Registration', query: this.$route.query });
        } else if (tab === 'login') {
          this.$router.push({ name: 'Login', query: this.$route.query });
        }
      },
    }
  };
</script>
<style lang="scss">
  .auth__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 366px;
    padding: 25px 30px;
    background: #ffffff;
  }

  .tabs-auth__container {
    width: 100%;
    margin: 0 auto 30px;
  }

  .tabs-auth__container .tab-auth {
    width: 49%;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: bold;
    color: #353535;
    text-align: center;
    background-color: $gray50;
  }

  .tabs-auth__container .tab-auth.active {
    background-color: $blue100;
    color: $blue600;
  }

  .tab-auth {
    &.left { border-radius: 10px 0 0 10px; }
    &.right { border-radius: 0 10px 10px 0; }
  }
</style>
